import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import verifyUserStoreModule from './verifyUserStore.js'
import verifyPhoneStoreModule from './verifyPhoneStore.js'
import { locationStoreModule } from './locationStore.js'
import { missingInfoStore } from './missingInfoStore.js'
import VueCryptojs from 'vue-cryptojs'
import { setItem, deleteItem } from '../utils/indexdb.js'

Vue.use(Vuex)
Vue.use(VueCryptojs)

export default new Vuex.Store({
	namespace: true,
	modules: {
		verifyUserStore: verifyUserStoreModule,
		locationStore: locationStoreModule,
		verifyPhoneStore: verifyPhoneStoreModule,
		missingInfoStore
	},
	state: {
		drawer: false,
		mini: true,
		token: '',
		refreshToken: '',
		id: '',
		name: '',
		email: '',
		country: null,
		isdCode: null,
		contact: null,
		qrCode: null,
		consultancyFees: null,
		user: {
			userName: '',
			password: ''
		},
		sectionInfo: null,
		prescription: [],
		profileImage: '',
		screenName: null,
		position: {
			lat: null,
			long: null
		},
		appVersion: null
	},
	plugins: [
		createPersistedState({
			paths: [
				'id',
				'token',
				'user',
				'name',
				'email',
				'country',
				'prescription',
				'profileImage',
				'isdCode',
				'appVersion'
			]
		})
	],
	mutations: {
		CLOSE_DRAWER(state) {
			state.drawer = false
			state.mini = true
		},
		toggleDrawer(state) {
			state.drawer = !state.drawer
		},
		toggleMini(state, paylod) {
			state.mini = paylod
		},
		setToken(state, token) {
			state.token = token
			if (token) setItem('token', token)
			else deleteItem('token')
		},
		setRefreshToken(state, refreshToken) {
			state.refreshToken = refreshToken
			if (refreshToken) setItem('refreshToken', refreshToken)
			else deleteItem('refreshToken')
		},
		setUserId(state, id) {
			state.id = id
			if (id) setItem('userId', id)
			else deleteItem('userId')
		},
		setUserName(state, name) {
			state.name = name
		},
		setUserEmail(state, email) {
			state.email = email
		},
		setUserCountry(state, country) {
			state.country = country
		},
		setUserIsd(state, isdCode) {
			state.isdCode = isdCode
		},
		setScreenName(state, screenName) {
			state.screenName = screenName
		},
		setUserBlank(state) {
			state.token = ''
			state.refreshToken = ''
			state.id = ''
			state.name = null
			state.email = null
			state.country = null
			state.isdCode = null
			state.profileImage = ''
			state.contact = null
			state.qrCode = null
			state.consultancyFees = null
			deleteItem('userId')
			deleteItem('token')
			deleteItem('refreshToken')
		},
		setUser(state, user) {
			state.user = user
		},
		setPrescriptionList(state, prescription) {
			state.prescription = prescription
		},
		setProfileImage(state, image) {
			state.profileImage = image
		},
		setUserPosition(state, { lat, long }) {
			state.position.lat = lat
			state.position.long = long
		},
		setUserData(state, user) {
			state.name = user.name
			state.email = user.email
			state.country = user.country
			state.isdCode = user.isdCode
			state.profileImage = user.profileImage
			state.contact = user.contact
			state.qrCode = user.qrCode
			state.consultancyFees = user.consultancyFees
		},
		setSectionInfo(state, payload) {
			state.sectionInfo = payload
		},
		setAppVersion(state, appVersion) {
			state.appVersion = appVersion
			setItem('app-version', appVersion)
		}
	},
	actions: {
		TOGGLE_DRAWER({ commit }) {
			commit('toggleDrawer')
		},
		TOGGLE_MINI({ commit }, paylod) {
			commit('toggleMini', paylod)
		}
	},
	getters: {
		DRAWER_STATE(state) {
			return state.drawer
		},
		getToken(state) {
			setItem('token', state.token)
			return state.token
		},
		getUserId(state) {
			setItem('userId', state.id)
			return state.id
		},
		getUserName(state) {
			return state.name
		},
		getUserEmail(state) {
			return state.email
		},
		getUserCountry(state) {
			return state.country
		},
		getUserIsd(state) {
			return state.isdCode
		},
		getUser(state) {
			return state.user
		},
		getRefreshToken(state) {
			return state.refreshToken
		},
		getPrescriptionList(state) {
			return state.prescription
		},
		getProfileImage(state) {
			return state.profileImage
		},
		getScreenName(state) {
			return state.screenName
		},
		getAppVersion(state) {
			return state.appVersion
		}
	}
})
